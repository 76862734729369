//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-548:_4308,_5992,_8640,_4652,_2232,_4356,_2348,_5632;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-548')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-548', "_4308,_5992,_8640,_4652,_2232,_4356,_2348,_5632");
        }
      }catch (ex) {
        console.error(ex);
      }