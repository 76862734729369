const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/collections';

function responseHandler({ Facets, ...rest }) {
  const filteredFacets = Facets.filter((f) => {
    if (wheelsFields.includes(f.FieldName) && !f.Values.length) {
      return false;
    }

    return true;
  });

  return { Facets: filteredFacets, ...rest };
}

function InitFunc() {
  const { document } = window;
  if (document.querySelector('#cm_results')) {
    const searchResultWrapper = document.querySelector('.page-container .rte');
    searchResultWrapper.classList.remove('rte');
  }

  // show only first widget and only on the home page
  const multicategoryWrapperElem = document.querySelector('.template-index .themevale_MultiCategory_wrapper');
  if (multicategoryWrapperElem) {
    multicategoryWrapperElem.style.display = 'block';
  }
}

export default {
  platform: 'shopify',
  InitFunc,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    searchResultsImageWidth: 270,
    searchResultsImageHeight: 270,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    onVehicleSelected: () => window.document.querySelector('#site-nav-mobile .close_menu')?.click(),
    categorySelectionPages: [{ pathname: categorySelectionPageUrl, field: 'category' }],
    categorySelectionPageRedirect: true,
  },
  facets: {
    selectFacet: { fields: ['wheel_backspace'] },
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.template-collection .page-collections',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: '.halo-header-PC .header-search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.halo-header-mobile .item--searchMobile',
        class: 'navUser-action items',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { insertBefore: '.halo-header-PC .item .item--cart', class: 'item item--garage' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        insertAfter: '.site-nav-mobile.two .item:nth-child(2)',
        wrapper: 'li',
        class: 'menu-lv-1 item',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: 'body.template-index .cm_vehicle-search.cm_tab-content',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 490,
    },
    {
      name: 'HomeWheelBar',
      type: 'RequestPanel',
      location: 'body.template-index .cm_wheel-search.cm_tab-content',
      template: 'HomeWheelBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: 'body.template-product .product-form__item' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.template-list-collections .page-collections',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
